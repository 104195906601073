@import "../../../../main.module";

$imageMaxSize: 200px;

.productLine {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.actionDiv {
  flex: none;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  gap: 20px;
}

.externalList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.img {
  width: $imageMaxSize;
  height: $imageMaxSize;
  object-fit: contain;
  flex: none;
}

.noAssociate {
  border: dashed orange;
}


.line {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 1px;
  border-radius: 50%;
  background-color: #b6b6b6;
}

.quantityDiv {
  padding: 0 4px;

}