.main {
  display: flex;
  flex-direction: row;

  height: 100vh;
}

.content {
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
}

.marginRight {
  margin-right: 10px;
}