.line {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.left {
  justify-content: start

}

.right {
  justify-content: end;
}