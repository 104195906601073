@import "../main.module";

.filterArea {
  top: 0;
  position: sticky;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;

  flex: 1;
}

.resultArea {
  flex: 4;
}

.filterBlock {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: $secondaryColor;
}

.filterRootBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.list {
  display: flex;
  flex-direction: row;

  gap: 15px;
}