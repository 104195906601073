.modalRoot {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.85);
  display: none;

  &.open {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
}

.modalWindow {
  background: white;
  border-radius: 15px;
  padding: 20px;

  max-width: 80vw;
  max-height: 90vh;

  overflow: auto;
}